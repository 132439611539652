<template>
	<section class="plan-tratament-nou">
    <h1>Creaza plan de tratament</h1>

    <form @submit.prevent="handleCreate">
      <label for="name" class="flex flex-between align-center">
        <span>nume plan:</span>
        <input type="text"
               cy="nume-plan"
               v-model="plan.nume"
               id="name"
               placeholder="nume plan tratament">
      </label>
      <label for="obs">
        <span class="f-16">observatii generale:</span>
        <br>
        <br>
        <textarea id="obs"
                  cy="obs-plan"
                  v-model="plan.observatii"
                  cols="30" rows="10" />
      </label>

      <button class="m-left-auto block noborder"
              cy="creaza-plan-btn"
              :disabled="!plan.nume">
        CREAZA
      </button>
    </form>
	</section>
</template>

<script>
	export default {
		name: 'PlanTratamentNou',
    data () {
      return {
        plan: {
          nume: '',
          observatii: ''
        }
      }
    },
    methods: {
      handleCreate () {
        this.$store.dispatch('create_plan', {
          plan: this.plan,
          id: this.$route.params.id
        })
      }
    }
	}
</script>

<style lang="less">
  @import "../assets/styles/var";
	.plan-tratament-nou {
    form {
      width: 727px;
    }
    label {
      margin-bottom: 16px;
      color: grey;
      &[for="name"] {
        span {
          margin-right: 16px;
          flex: 0 0 auto;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }
    input,
    textarea {
      width: 100%;
      resize: none;
      border: 1px solid #C4C4C4;
    }
    input {
      font-size: 1.8rem;
      padding-left: 16px;
      border-radius: 5px;
    }
    textarea {
      height: 100px;
      font-size: 1.6rem;
      padding: 12px;
      border-radius: 5px;
    }
    button {
      margin-top: 18px;
      background-color: @pt-blue;
      color: white;
      font-size: 1.8rem;
      border-radius: 4px;
      padding: 6px 24px;
      cursor: pointer;
      &:disabled {
        cursor: default;
        opacity: 0.5;
      }
      &:not(:disabled):hover {
        transition: background-color 0.1s ease-in-out;
        background-color: darken(@pt-blue, 20%, relative);
      }
    }
	}
</style>
